import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "linear-gradient(45deg, rgba(9,200,149,1) 0%, rgba(9,200,189,1) 100%)"};
  color: ${(p) => (p.color ? "#09C8A9" : "#fff")};
  font-size: 1rem;
  width: 100%;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: ${props => props.marginTop || "0.625rem"};
  max-width: 180px;
  box-shadow: 0 4px 8px rgb(23 31 114 / 20%);
  color: #fff;
  transition: all 0.2s ease-in-out;

  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 414px) {
    min-width: 100px;
    font-size: 0.8rem;
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    transform: scale(1.05);
  }
`;
