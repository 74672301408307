import { SvgIcon } from "../Image";
import { StyledStoreLink } from "./styles";

interface StoreLinkProps {
    href: string;
    src: string;
}
  
const StoreLink = ({ href, src }: StoreLinkProps) => {
    return (
      <StyledStoreLink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="" height="42px"/>
      </StyledStoreLink>
    );
  };

export default StoreLink;