import { Row, Col } from "antd";
import { LinkedinOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "../../common/Image";
import Container from "../../common/Container";
import SocialLink from "../../common/SocialLink";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import StoreLink from "../../common/StoreLink";


const Footer = () => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };
  const { t } = useTranslation("common");
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("company")}</Title>
              <b><Para>Voam Technologies B.V.</Para></b>
              <Para>
                {t("companylocation")}
              </Para>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("policy")}</Title>
              <Large href="https://www.voam.io/privacy" left="true">
                {t("privacy")}
              </Large>
              <Large left="true" href="https://www.voam.io/terms">
                {t("terms")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <label htmlFor="select-lang">
                <Title>{t("language")}</Title>
              </label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="svg/united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("nl")}>
                  <SvgIcon
                    src="svg/nl.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("appdownloads")}</Title>
              <StoreLink
                href="https://apps.apple.com/nl/app/voam/id6451394043"
                src="badges/app-store-badge.svg"
              />
              <StoreLink
                href="https://play.google.com/store/apps/details?id=io.voam.voam&pcampaignid=web_share"
                src="badges/google-play-badge.svg"
              />
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "1rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="svg/logo_color_white.svg"
                  aria-label="homepage"
                  width="140px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <SocialLink
              href="https://www.linkedin.com/company/voamtechnologies/"
              label="LinkedIn"
              icon={<LinkedinOutlined color="#fff" size={40}/>}
            />
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default Footer;
