import styled from "styled-components";

export const StyledSocialLink = styled("a")`
    display: inline-block;
    margin-right: 3rem;
    transition: all 0.2s ease-in-out;
    display: flex;
    color: #fff;
    font-size: 2rem;

    &:hover,
    &:active,
    &:focus {
        color: #F2B635;
    }
`;