import React from "react";
import { StyledSocialLink } from "./styles";

interface SocialLinkProps {
    href: string;
    label: string;
    icon: React.ReactNode;
}
  
const SocialLink = ({ href, icon, label }: SocialLinkProps) => {
    return (
      <StyledSocialLink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={label}
      >
        {icon}
      </StyledSocialLink>
    );
  };

export default SocialLink;