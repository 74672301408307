import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  width: 100%;
  align-self: center;
  padding: 0.2rem 0.5rem;
  border-bottom: 1px solid #f2f2f3;

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`;

export const HeaderContainer = styled("div")`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const LogoContainer = styled(Link)`
  display: flex;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 1000px) {
    display: block;
  }

  display: none;
  margin: 0.5rem;

  svg {
    fill: #1d1a2a;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const LoginLink = styled("a")`
  font-size: 1rem;
  color: #1d1a2a;
  transition: color 0.2s ease-in;

`;

export const NavigationLink = styled(Link)`
  font-size: 1rem;
  color: #1d1a2a;
  transition: color 0.1s ease-in;
  padding: 0.5rem;
  border-radius: 8px;

  &:hover {
    background-color: #f2f2f3;
    color: inherit;
  }
`;

export const NavLinkLikeDiv = styled(NavLink)`
  font-size: 1rem;
  color: #1d1a2a;
  transition: color 0.1s ease-in;
  padding: 0.5rem;
  border-radius: 8px;

  &:hover {
    color: inherit;
    background-color: #f2f2f3;
  }
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #1d1a2a;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;

export const Span = styled("span")`
  cursor: pointer;
`;
