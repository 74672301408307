import { useState } from "react";
import { Row, Col, Drawer, Dropdown, Space, MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "../../common/Image";
import { FancyButton } from "../../common/FancyButton";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  NavLinkLikeDiv,
  NavigationLink,
  Label,
  Outline,
  Span,
  LoginLink,
  HeaderContainer,
} from "./styles";

const Header = () => {
  const [visible, setVisibility] = useState(false);
  const { t } = useTranslation("common");

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const NavMenu = () => {
    const solutionsItems: MenuProps["items"] = [
      {
        "key": "sales",
        "label": <NavigationLink to="/sales"><Span>{t("solution_sales")}</Span></NavigationLink>
      },
      {
        "key": "recruitment",
        "label": <NavigationLink to="/recruitment"><Span>{t("solution_recruitment")}</Span></NavigationLink>
      },
      {
        "key": "technicians",
        "label": <NavigationLink to="/technicians"><Span>{t("solution_technicians")}</Span></NavigationLink>
      },
    ]
    return (
      <Space direction={visible ? "vertical" : "horizontal"} align="center" size={visible ? "large" : "middle"}>
        <LogoContainer to="/" aria-label="homepage">
          <SvgIcon src="svg/logo_color.svg" width="100px" height="50px" />
        </LogoContainer>
        <Dropdown menu={{ items: solutionsItems }}>
          <NavLinkLikeDiv>
            <Space size={2} style={{ margin: 0 }}><Span>{t("solutions")}</Span><DownOutlined /></Space>
          </NavLinkLikeDiv>
        </Dropdown>
        <NavLinkLikeDiv>
          <Span><a style={{ margin: 0, padding: 0 }} href="https://docs.voam.io">{t("documentation")}</a></Span>
        </NavLinkLikeDiv>
        <NavigationLink to="/pricing">
          <Span>{t("pricing")}</Span>
        </NavigationLink>
        <NavigationLink to="/contact">
          <Span>{t("contact")}</Span>
        </NavigationLink>
        <a href="https://www.producthunt.com/posts/voam?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-voam" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=443049&theme=light" alt="voam - Write&#0032;high&#0032;quality&#0032;CRM&#0032;documentation&#0032;10x&#0032;faster&#0032;by&#0032;voice | Product Hunt" style={{width: "250px", height: "44px"}} width="250" height="44" /></a>
      </Space>
    );
  };

  const ActionMenu = () => {
    return (
      <Space direction={visible ? "vertical" : "horizontal"} align="center" size={visible ? "large" : "middle"} style={{ marginTop: visible ? "18px" : "0px" }}>
        <NavLinkLikeDiv>
          <LoginLink href="https://platform.voam.io/login"><Span>{t("login")}</Span></LoginLink>
        </NavLinkLikeDiv>
        <FancyButton marginTop="0px" onClick={() => window.location.href = "https://voam.io/signup"}>
          {t("signup")}
        </FancyButton>
      </Space>
    );
  };

  return (
    <HeaderSection>
      <HeaderContainer>
        <Row justify="space-between" align="middle" gutter={30}>
          <NotHidden>
            <NavMenu />
          </NotHidden>
          <NotHidden>
            <ActionMenu />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <NavMenu />
          <ActionMenu />
        </Drawer>
      </HeaderContainer>
    </HeaderSection>
  );
};

export default Header;
