import { StyledButton } from "./styles";
import { ButtonProps } from "../types";

export const FancyButton = ({
  color,
  fixedWidth,
  children,
  onClick,
  marginTop,
}: ButtonProps) => (
  <StyledButton color={color} fixedWidth={fixedWidth} onClick={onClick} marginTop={marginTop}>
    {children}
  </StyledButton>
);
