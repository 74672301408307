import styled from "styled-components";

export const StyledImg = styled.img`
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 3rem;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
  border-radius: 12px;
  max-height: 500px;
`;

export const StyledVideo = styled.video`
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 3rem;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
  border-radius: 12px;
  max-height: 500px;
`;
