import { SvgIconProps } from "../types";
import { StyledImg, StyledVideo } from "./styles";

export const SvgIcon = ({ src, width, height }: SvgIconProps) => (
  <img src={`/img/${src}`} alt={src} width={width} height={height}/>
);

export const LargeImage = ({ src, width, height }: SvgIconProps) => {
  if (src.endsWith(".mp4")) {
    return (
      <StyledVideo width={width} height={height} autoPlay loop muted>
        <source src={`/img/${src}`} type="video/mp4" />
      </StyledVideo>
    );
  }
  return <StyledImg src={`/img/${src}`} alt={src} width={width} height={height} />;
}
