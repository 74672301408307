import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "LibreFranklin Regular";
        src: url("/fonts/LibreFranklin-Regular.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "LibreFranklin Medium";
        src: url("/fonts/LibreFranklin-Medium.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "LibreFranklin SemiBold";
        src: url("/fonts/LibreFranklin-SemiBold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "LibreFranklin Bold";
        src: url("/fonts/LibreFranklin-Bold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "LibreFranklin ExtraBold";
        src: url("/fonts/LibreFranklin-ExtraBold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "LibreFranklin Black";
        src: url("/fonts/LibreFranklin-Black.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'LibreFranklin Regular', sans-serif;
        overscroll-behavior: none;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    button {
        font-family: 'LibreFranklin Medium', sans-serif;
    }

    input,
    textarea {
        border-radius: 25px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            box-shadow: #09C8A9 0px 0px 0px 2px;
        }
    }

    h1 {
        font-family: 'LibreFranklin Black', serif;
        color: #141123;
        font-size: 56px;
        line-height: 1.18;
        margin: 0.5rem 0;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    h2 {
        font-family: 'LibreFranklin ExtraBold', serif;
        color: #141123;
        font-size: 40px;
        line-height: 1.18;
        margin: 0.2rem 0;

        @media only screen and (max-width: 890px) {
          font-size: 32px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 24px;
        }
    }

    h3 {
        font-family: 'LibreFranklin Bold', serif;
        color: #141123;
        font-size: 32px;
        line-height: 1.18;
        margin: 0.5rem 0;

        @media only screen and (max-width: 890px) {
          font-size: 28px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 20px;
        }
    }

    p, li {
        color: #1D1A2ABF;
        font-size: 21px;        
        line-height: 1.41;
        margin: 0;
        padding: 0;
    }

    b {
        font-family: 'LibreFranklin SemiBold', sans-serif;
        color: #1D1A2A;
        font-size: 21px;        
        line-height: 1.41;
    }

    li::marker {
        font-size: 28px;
    }

    ul {
        list-style-image: url("img/svg/bullet.svg");
        list-style-position: outside;
        ::marker {
            font-weight: 600;
            font-size: 40px;
        }
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #09C8A9;

        :hover {
            color: #09C8A9;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    img {
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
`;
