const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/sales", "/solutions/sales"],
    exact: true,
    component: "SolutionsSales",
  },
  {
    path: ["/recruitment", "/solutions/recruitment"],
    exact: true,
    component: "SolutionsRecruitment",
  },
  {
    path: ["/technicians", "/solutions/technicians"],
    exact: true,
    component: "SolutionsTechnicians",
  },
  {
    path: ["/pricing"],
    exact: true,
    component: "Pricing",
  },
  {
    path: ["/contact"],
    exact: true,
    component: "Contact",
  },
];

export default routes;
