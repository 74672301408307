import styled from "styled-components";

export const StyledStoreLink = styled("a")`
    display: inline-block;
    padding-top: 0.5rem;
    padding-right: 0.7rem;
    transition: all 0.2s ease-in-out;
    
    &:hover,
    &:active,
    &:focus {
        color: #F2B635;
    }
`;